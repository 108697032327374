import { setToken, getToken, getSessionStorage, getTokenExpired } from '@/libs/utils'
import Base64 from 'base-64'
export default {
  state: {
    uid: '', // 用户uid
    shareKey: '', // 邀请有礼的shareKey
    shareType: '', // 邀请有礼分享来源：Email,Link,Social
    token: '',
    tokenExpired: 0,
    companyUser: false, // 是否是企业用户
    country: '',
    loginFormData: {
      // 账号密码登录时需要的form表单
      username: '',
      accountType: 'email', // email or phone
      codeId: '',
      code: '',
      isRegister: 0, // 是否为注册
    },
    userInfo: {},
    inviteCode: '', // 企业邀请code
    loginedAccounts: [], // 客户端已登录的账号uid列表
  },
  getters: {
    shareKey: state => {
      // shareKey 只在本次会话期内有效
      const shareKey = getSessionStorage('shareKey')
      if (shareKey) {
        state.shareKey = shareKey
        return shareKey
      }
      return state.shareKey
    },
    shareType: state => {
      // shareType 只在本次会话期内有效
      const shareType = getSessionStorage('shareType')
      if (shareType) {
        state.shareType = shareType
        return shareType
      }
      return state.shareType
    },
    token: state => {
      // 获取token，state中没有的话获取session
      let token = state.token
      let expired = state.tokenExpired
      if (!token) {
        token = getToken()
        expired = getTokenExpired()
        state.token = token
        state.tokenExpired = expired
      }
      return !token || !expired || expired < new Date().getTime() ? '' : token
    },
    tokenExpired: state => {
      // token过期时间
      let tokenExpired = state.tokenExpired
      if (!tokenExpired) {
        tokenExpired = getTokenExpired()
        state.tokenExpired = tokenExpired
      }
      return tokenExpired
    },
    uid: state => {
      // 通过token解码uid
      if (state.uid === '' && state.token !== '') {
        // token中的json使用了rawUrlEncoding编码，用'-'和'_'替换了'+'和'/'，解码需要替换回来(否则中文下会解码失败)
        const userDataJson = state.token.split('.')[1].replaceAll('-', '+').replaceAll('_', '/')
        const tokenData = state.token ? JSON.parse(Base64.decode(userDataJson)) : null
        if (tokenData) {
          state.uid = tokenData.Uid
          return state.uid
        }
        return 0
      }
      return state.uid
    },
    country: state => state.country || 'Unknown',
    inviteCode: state => {
      if (!state.inviteCode) {
        const inviteCode = getSessionStorage('inviteCode')
        if (inviteCode) {
          state.inviteCode = inviteCode
          return inviteCode
        }
      }
      return state.inviteCode
    },
    loginedAccounts: state => {
      // 客户端已登录的账号uid列表
      return state.loginedAccounts
    },
  },
  mutations: {
    setToken(state, { token, expired }) {
      setToken(token, expired)
      state.token = token
      state.tokenExpired = expired
    },
  },
  actions: {
    setToken({ commit }, { token, expired }) {
      commit('setToken', { token, expired })
    },
  },
}
