import { getLocalStorage, getToken, isInMacWebview, setLocalStorage } from '@/libs/utils'
import $store from '@/store'
import $router from '@/router'
import { getOSName } from '@/libs/tools'
/**
 * 设置访问来源，用于安全的向父窗口发送数据
 */
export const setReferrer = () => {
  setLocalStorage('referrer', document.referrer)
}

/**
 * 获取访问来源
 */
export const getReferrer = () => {
  return getLocalStorage('referrer')
}

/**
 * 判断是否登录（返回token是否过期）
 * @returns {Boolean}
 */
export const isLogin = () => {
  return getToken() !== ''
}

/**
 * webview 隐藏时
 */
export const webviewHidden = () => {
  $store.state.app.webviewVisibilityState = false
}

/**
 * webview 显示时
 */
export const webviewVisibility = () => {
  $store.state.app.webviewVisibilityState = true
}

/**
 * 根据语言跳转到对应登录页
 * @param {String} redirect 登录成功后回跳的页面，为空则跳转到个人中心
 * @param {Boolean} isConpanyManager 是否是管理员登录
 */
export const redirectToLogin = (redirectUri = '', isConpanyManager = false) => {
  const query = $router.currentRoute.value.query
  if (redirectUri) query.redirectUri = redirectUri
  if (isConpanyManager) {
    $router.replace({ name: 'loginManager', query })
  } else if ($store.getters.isZh) {
    // 手机端默认进入手机号邮箱登录界面
    if (['IOS', 'ANDROID'].includes(getOSName())) {
      $router.replace({ name: 'loginAccountInput', query })
    } else {
      $router.replace({ name: 'loginWechat', query })
    }
  } else {
    $router.replace({ name: 'loginAccountInput', query })
  }
}

/**
 * 跳转企业登录页
 */

export const redirectToCompanyManagerLogin = () => {
  const query = $router.currentRoute.value.query
  $router.replace({ name: 'loginAccountInput', query })
}

/**
 * 跳转到个人中心
 */
export const redirectToAccountCenter = () => {
  const query = $router.currentRoute.value.query
  const hash = $router.currentRoute.value.hash
  $router.replace({ name: 'accountCenter', query, hash })
}
/**
 * 跳转到企业管理后台
 */
export const redirectToCompanyAdmin = () => {
  const query = $router.currentRoute.value.query
  if (query?.redirectUri) {
    location.href = decodeURIComponent(query.redirectUri)
  } else {
    $router.replace({ name: 'companyManagement' })
  }
}

/**
 * 根据窗口大小获取抽屉弹窗宽度
 * @params {String} type 不同弹窗尺寸配置：login|accountCenter
 * @returns 百分比
 */
export const getDrawerSize = (type = 'accountCenter') => {
  const bodyW = window.innerWidth
  const sizeConfig = {
    login: {
      w1700: '30%',
      w1100: '50%',
    },
    accountCenter: {
      w1700: '50%',
      w1100: '75%',
    },
  }
  const sizeData = sizeConfig[type] || sizeConfig.accountCenter
  if (bodyW > 1700) {
    return sizeData.w1700
  } else if (bodyW > 1100) {
    return sizeData.w1100
  } else {
    return '100%'
  }
}

/**
 * 获取收银台地址
 * @param {Object} query 自定义url参数
 * @param {Number} goodsType 0:个人版，1：企业版， 3:sign收银台
 */
// eslint-disable-next-line no-unused-vars
export const getCheckoutUrl = (query, goodsType = 0) => {
  const { language, isZh, schemeName, deviceType, productName, source, subPid } = $store.getters
  const urlQuery = {
    language,
    schemeName,
    deviceType,
    productName,
    source,
    subPid,
    ...query,
    // subscriptionType (因为现在季卡去掉了，原来用户如果是季卡，跳转过去会导致默认季卡，收银台显示会有问题)
  }
  if (isInMacWebview()) {
    // mac内嵌页的话需要加上这个参数，供mac端确认是跳转网页还是内购
    urlQuery.iap = 1
  }
  let checkoutUrl = ''
  if (isZh) {
    if (goodsType === 0) {
      checkoutUrl = import.meta.env.VITE_CHECKOUT_URL_CN + '/updf'
    } else if (goodsType === 1) {
      checkoutUrl = import.meta.env.VITE_CHECKOUT_URL_CN + '/updf-company'
    } else if (goodsType === 3) {
      checkoutUrl = import.meta.env.VITE_CHECKOUT_SIGN_URL_EN
    }
  } else if (goodsType === 0) {
    checkoutUrl = import.meta.env.VITE_WEBSITE_HOST_COM + '/pricing-individuals'
  } else if (goodsType === 1) {
    checkoutUrl = import.meta.env.VITE_WEBSITE_HOST_COM + '/pricing-enterprises'
  } else if (goodsType === 3) {
    checkoutUrl = import.meta.env.VITE_CHECKOUT_SIGN_URL_EN
  }
  if (import.meta.env.MODE !== 'prod') {
    // 非正式环境下，需要新增isCN参数
    if (isZh) {
      urlQuery.isCN = 1
    } else urlQuery.isCN = 0
  }
  return (
    checkoutUrl +
    '?' +
    Object.keys(urlQuery)
      .map(key => `${key}=${urlQuery[key]}`)
      .join('&')
  )
}
